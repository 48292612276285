<template>
  <div class="privacy">
    <h1>Política de Privacidad</h1>
    <p>Aquí va la política de privacidad de tu servicio...</p>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPage'
};
</script>

<style scoped>
.privacy {
  padding: 20px;
}
</style>
