<template>
  <div class="surebet-calculator-page">
    <header>
      <h1>Calculadora de Surebets</h1>
    </header>
    <main>
      <form>
        <div class="input-group">
          <label for="totalStake">Cantidad Total a Apostar (€)</label>
          <input type="number" v-model.number="totalStake" id="totalStake" @input="handleTotalStakeChange" required />
        </div>
        <div class="input-group">
          <label for="roundStake">Redondear a la unidad</label>
          <input type="number" v-model.number="roundValue" id="roundValue" @input="calculateSurebet" />
          <input type="checkbox" v-model="roundStake" id="roundStake" @input="calculateSurebet" />
        </div>
        <div class="bet-input" v-for="(bet, index) in bets" :key="index">
          <div class="input-group">
            <label :for="'odds' + index">Cuota {{ index + 1 }}</label>
            <input type="number" step="0.01" v-model.number="bet.odds" :id="'odds' + index" @input="calculateSurebet" required />
          </div>
          <div class="input-group">
            <label :for="'stake' + index">Cantidad a Apostar</label>
            <input type="number" step="0.01" v-model.number="bet.stake" :id="'stake' + index" @input="handleBetStakeChange" />
          </div>
          <div class="input-group">
            <label :for="'profit' + index">Beneficio (€)</label>
            <input type="number" :value="bet.profit.toFixed(2)" :id="'profit' + index" readonly />
          </div>
          <div class="input-group">
            <label :for="'percentage' + index">Porcentaje (%)</label>
            <input type="number" :value="bet.percentage.toFixed(2)" :id="'percentage' + index" readonly />
          </div>
        </div>
        <button type="button" @click="addBet" :disabled="bets.length >= 3">Añadir Casa de Apuesta</button>
      </form>
    </main>
  </div>
</template>

<script>
export default {
  data() {
    return {
      totalStake: 100,
      roundStake: false,
      roundValue: 1,
      bets: [
        { odds: 2, stake: 50, profit: 0, percentage: 0 },
        { odds: 2, stake: 50, profit: 0, percentage: 0 },
      ],
      adjustingBetStake: false,
    };
  },
  methods: {
    calculateSurebet() {
      if (this.adjustingBetStake) {
        this.adjustingBetStake = false;
        return;
      }

      const totalStake = this.roundStake && this.roundValue > 0
        ? Math.round(this.totalStake / this.roundValue) * this.roundValue
        : this.totalStake;

      const totalInverseOdds = this.bets.reduce((total, bet) => total + 1 / bet.odds, 0);
      const investment = totalStake;

      this.bets.forEach(bet => {
        bet.stake = (investment / (bet.odds * totalInverseOdds)).toFixed(2);
        if (this.roundStake) {
          bet.stake = (Math.round(bet.stake / this.roundValue) * this.roundValue).toFixed(2);
        }
        bet.profit = parseFloat((bet.stake * bet.odds - investment).toFixed(2));
        bet.percentage = parseFloat(((bet.profit / investment) * 100).toFixed(2));
      });
    },
    addBet() {
      if (this.bets.length < 3) {
        this.bets.push({ odds: 2, stake: 0, profit: 0, percentage: 0 });
      }
      this.calculateSurebet();
    },
    handleTotalStakeChange() {
      this.calculateSurebet();
    },
    handleBetStakeChange() {
      this.adjustingBetStake = true;
      const totalStake = this.bets.reduce((total, bet) => total + parseFloat(bet.stake), 0);
      this.totalStake = totalStake.toFixed(2);
      this.calculateSurebet();
    },
  },
  watch: {
    bets: {
      handler() {
        this.calculateSurebet();
      },
      deep: true,
    },
    totalStake() {
      this.calculateSurebet();
    },
    roundStake() {
      this.calculateSurebet();
    },
    roundValue() {
      this.calculateSurebet();
    },
  },
  mounted() {
    this.calculateSurebet();
  },
};
</script>

<style scoped>
.surebet-calculator-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

header {
  text-align: center;
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
}

.bet-input, .input-group {
  margin-bottom: 15px;
}

.bet-input label, .input-group label {
  display: block;
  margin-bottom: 5px;
}

.bet-input input, .input-group input {
  width: calc(100% - 20px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #28a745;
  color: white;
  cursor: pointer;
  margin-top: 10px;
}

button:hover {
  background-color: #218838;
}

@media (max-width: 600px) {
  .surebet-calculator-page {
    padding: 10px;
  }

  form {
    flex-direction: column;
  }

  .input-group, .bet-input {
    width: 100%;
  }
}
</style>
