<template>
  <div>
    <Banner />
    <ServicesSection />
    <WhyChooseUs />
    <UserTestimonials />
    <!-- Otros componentes específicos para la página de inicio -->
  </div>
</template>

<script>
import Banner from '@/components/SiteBanner.vue';
import ServicesSection from '@/components/ServicesSection.vue';
import WhyChooseUs from '@/components/WhyChooseUs.vue';
import UserTestimonials from '@/components/UserTestimonials.vue';

export default {
  name: 'HomePage',
  components: {
    Banner,
    ServicesSection,
    WhyChooseUs,
    UserTestimonials
  }
};
</script>
