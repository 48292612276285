<template>
  <section class="services">
    <div class="container">
      <h2>Nuestros Servicios</h2>
      <div class="services-list">
        <div class="service-card" v-for="service in services" :key="service.id">
          <img :src="service.icon" :alt="service.title" class="service-icon" />
          <h3>{{ service.title }}</h3>
          <p>{{ service.description }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ServicesSection',
  data() {
    return {
      services: [
        {
          id: 1,
          title: 'Análisis de Estadísticas',
          description: 'Obtén análisis detallados de las estadísticas de cada partido.',
          //icon: require('@/assets/statistics-icon.png')
        },
        {
          id: 2,
          title: 'Consejos de Apuestas',
          description: 'Recibe los mejores consejos para maximizar tus ganancias.',
          //icon: require('@/assets/tips-icon.png')
        },
        {
          id: 3,
          title: 'Herramientas de Pronóstico',
          description: 'Utiliza nuestras herramientas avanzadas para pronosticar resultados.',
          //icon: require('@/assets/tools-icon.png')
        }
      ]
    };
  }
};
</script>

<style scoped>
.services {
  background-color: white;
  padding: 50px 20px;
  text-align: center;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

h2 {
  color: darkgray; /* Título en gris oscuro */
  font-size: 2em;
  margin-bottom: 30px;
}

.services-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.service-card {
  background-color: lightgray; /* Fondo de tarjeta en gris claro */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
  text-align: center;
}

.service-icon {
  max-width: 100%;
  height: auto;
  margin-bottom: 15px;
}

h3 {
  color: darkgray; /* Título del servicio en gris oscuro */
  font-size: 1.5em;
  margin-bottom: 10px;
}

p {
  color: darkgray; /* Descripción en gris oscuro */
  font-size: 1em;
}
</style>
