<template>
  <section class="why-choose-us">
    <div class="container">
      <h2>¿Por qué elegirnos?</h2>
      <div class="reasons-list">
        <div class="reason-card" v-for="reason in reasons" :key="reason.id">
          <img :src="reason.icon" :alt="reason.title" class="reason-icon" />
          <h3>{{ reason.title }}</h3>
          <p>{{ reason.description }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'WhyChooseUs',
  data() {
    return {
      reasons: [
        {
          id: 1,
          title: 'Expertos en el sector',
          description: 'Nuestro equipo está compuesto por expertos con años de experiencia en apuestas deportivas.',
          //icon: require('@/assets/experts-icon.png')
        },
        {
          id: 2,
          title: 'Estrategias probadas',
          description: 'Utilizamos estrategias que han sido probadas y optimizadas para obtener los mejores resultados.',
          //icon: require('@/assets/strategies-icon.png')
        },
        {
          id: 3,
          title: 'Herramientas avanzadas',
          description: 'Ofrecemos herramientas avanzadas para ayudarte a tomar decisiones informadas y mejorar tus apuestas.',
          //icon: require('@/assets/tools-icon.png')
        },
        {
          id: 4,
          title: 'Soporte 24/7',
          description: 'Nuestro equipo de soporte está disponible las 24 horas del día, los 7 días de la semana para ayudarte con cualquier consulta.',
          //icon: require('@/assets/support-icon.png')
        }
      ]
    };
  }
};
</script>

<style scoped>
.why-choose-us {
  background-color: lightgray; /* Fondo en gris claro */
  padding: 50px 20px;
  text-align: center;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

h2 {
  color: darkgray; /* Título en gris oscuro */
  font-size: 2em;
  margin-bottom: 30px;
}

.reasons-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.reason-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 250px;
  text-align: center;
}

.reason-icon {
  max-width: 100%;
  height: auto;
  margin-bottom: 15px;
}

h3 {
  color: darkgray; /* Título de la razón en gris oscuro */
  font-size: 1.5em;
  margin-bottom: 10px;
}

p {
  color: darkgray; /* Descripción en gris oscuro */
  font-size: 1em;
}
</style>
