import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/Home.vue';
import TermsPage from '../views/Terms.vue';
import PrivacyPage from '../views/Privacy.vue';
import SurebetCalculatorPage from '@/views/SurebetCalculatorPage.vue';
import CalculatorMBPage from '@/views/CalculatorMBPage.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/terms',
    name: 'Terms',
    component: TermsPage
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: PrivacyPage
  },
  { 
	path: '/surebet-calculator',
	name: 'SurebetCalculator',
	component: SurebetCalculatorPage
  },
  { 
	path: '/calculator-mb',
	name: 'CalculatorMB',
	component: CalculatorMBPage
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
