<template>
  <div class="calculator">
    <h1>CALCULADORA DE MATCHED BETTING</h1>
    <div class="form">
      <!-- Formulario de entrada -->
      <div class="form-row">
        <div class="form-group">
          <label for="bet-type">Tipo de apuesta</label>
          <select v-model="betType" id="bet-type" @change="setDefaultRefundValues">
            <option value="normal">Normal</option>
            <option value="gratuita">Gratuita</option>
            <option value="reembolso">Reembolso</option>
          </select>
        </div>
        <div class="form-group">
          <label for="mode">Modo</label>
          <select v-model="mode" id="mode">
            <option value="simple">Simple</option>
          </select>
        </div>
      </div>
      <div class="form-row blue">
        <div class="form-group">
          <label for="stake">Importe</label>
          <div class="input-group">
            <input type="number" v-model="stake" id="stake" @input="updateRefundValue" />
            <span>€</span>
          </div>
        </div>
        <div class="form-group">
          <label for="back-odds">Cuota a favor</label>
          <input type="number" v-model="backOdds" id="back-odds" />
        </div>
      </div>
      <div class="form-row red">
        <div class="form-group">
          <label for="lay-odds">Cuota en contra</label>
          <input type="number" v-model="layOdds" id="lay-odds" />
        </div>
        <div class="form-group">
          <label for="commission">Comisión contra</label>
          <div class="input-group">
            <input type="number" v-model="commission" id="commission" />
            <span>%</span>
          </div>
        </div>
      </div>
      <div v-if="betType === 'reembolso'" class="form-row green">
        <div class="form-group">
          <label for="refund-value">Valor reembolso</label>
          <input type="number" v-model="refundValue" id="refund-value" />
        </div>
        <div class="form-group">
          <label for="refund-ret">Ret. reembolso</label>
          <div class="input-group">
            <input type="number" v-model="refundRet" id="refund-ret" />
            <span>%</span>
          </div>
        </div>
      </div>
      <button @click="calculate" class="calculate">CALCULAR</button>
      <button @click="clear" class="clear">LIMPIAR</button>
    </div>
    <!-- Resultado -->
    <div v-if="result !== null" class="result-container">
      <div class="result">
        <h2>Resultado</h2>
        <p>
          Con cuota <strong>{{ layOdds }}</strong>, podrías apostar <strong>{{ result.layStake }}€</strong>
        </p>
        <p>El riesgo será <strong>{{ result.risk }}€</strong></p>
        <p :style="{ color: result.totalProfit >= 0 ? 'green' : 'red' }">
          El beneficio total será de <strong>{{ result.totalProfit }}€</strong>
        </p>
        <p>[Rating = <strong>{{ result.rating }}%</strong>]</p>
      </div>
      <!-- Desglose de beneficios -->
      <div class="breakdown">
        <h3>Desglose de beneficios</h3>
        <table>
          <thead>
            <tr>
              <th>Ganador</th>
              <th>Bookmaker</th>
              <th>Exchange</th>
              <th v-if="betType === 'reembolso'">FreeBet</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Bookmaker</td>
              <td :style="{ color: 'green' }">{{ result.bookmakerWin }}€</td>
              <td :style="{ color: 'red' }">{{ result.exchangeLoss }}€</td>
              <td v-if="betType === 'reembolso'" :style="{ color: 'green' }">{{ result.freeBetBookmakerProfit }}€</td>
              <td :style="{ color: result.totalProfit >= 0 ? 'green' : 'red' }">{{ result.totalProfit }}€</td>
            </tr>
            <tr>
              <td>Exchange</td>
              <td :style="{ color: 'red' }">{{ result.bookmakerLoss }}€</td>
              <td :style="{ color: 'green' }">{{ result.exchangeWin }}€</td>
              <td v-if="betType === 'reembolso'" :style="{ color: 'green' }">{{ result.freeBetExchangeProfit }}€</td>
              <td :style="{ color: result.totalProfit >= 0 ? 'green' : 'red' }">{{ result.totalProfit }}€</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      betType: 'normal',
      mode: 'simple',
      stake: 10,
      backOdds: null,
      layOdds: null,
      commission: 2,
      refundValue: 10,
      refundRet: 70,
      result: null,
    };
  },
  methods: {
    calculateNormal() {
      if (this.backOdds && this.layOdds) {
      // Convert commission rate from percentage to decimal
        let varCommission = this.commission / 100;
        
        // Calculate the amount to lay on the exchange
        let layStake = (this.stake * this.backOdds) / (this.layOdds-varCommission);

        // Calculation of potential profit
        let bookmakerWin = this.stake * (this.backOdds - 1);
        let exchangeWin = layStake*(1-varCommission);

        // Calculation of risk on the exchange
        const risk = layStake * (this.layOdds - 1);

         // Calculation of total profit (net profit)
        let totalProfit = bookmakerWin - risk;

        // Rating calculation
        const rating = ((totalProfit + this.stake) / this.stake) * 100;

        this.result = {
            layStake: layStake.toFixed(2),
            risk: risk.toFixed(2),
            totalProfit: totalProfit.toFixed(2),
            rating: rating.toFixed(2),
            bookmakerWin: bookmakerWin.toFixed(2),
            bookmakerLoss: this.stake.toFixed(2),
            exchangeWin: exchangeWin.toFixed(2),
            exchangeLoss: risk.toFixed(2),
            message: `Con cuota ${this.layOdds}, podrías apostar ${layStake.toFixed(2)}€`
        };
      } else {
          this.result = 'Por favor, ingrese todas las cuotas';
      }
    },
    calculateFree() {
      if (this.backOdds && this.layOdds) {
        // Convert commission rate from percentage to decimal
         let varCommission = this.commission / 100;
        
        // CALCULADORA GRATUITA - TODO: Falta ajustar valores pero el calculo está bien
        // Calculate the amount to lay on the exchange
        let layStake = ((this.stake * this.backOdds)-this.stake) / (this.layOdds-varCommission);

        // Calculation of potential profit
        let bookmakerWin = this.stake * (this.backOdds - 1);
        let exchangeWin = layStake*(1-varCommission);

        // Calculation of risk on the exchange
        const risk = layStake * (this.layOdds - 1);

        // Calculation of total profit (net profit)
        let totalProfit = bookmakerWin - risk;

        // Rating calculation
        const rating = (((totalProfit + this.stake) / this.stake) * 100)-100;

        this.result = {
            layStake: layStake.toFixed(2),
            risk: risk.toFixed(2),
            totalProfit: totalProfit.toFixed(2),
            rating: rating.toFixed(2),
            bookmakerWin: bookmakerWin.toFixed(2),
            bookmakerLoss: this.stake.toFixed(2),
            exchangeWin: exchangeWin.toFixed(2),
            exchangeLoss: risk.toFixed(2),
            message: `Con cuota ${this.layOdds}, podrías apostar ${layStake.toFixed(2)}€`
        };
      } else {
          this.result = 'Por favor, ingrese todas las cuotas';
      }
    },
    calculateRefund() {
      if (this.backOdds && this.layOdds) {
        // Convert commission rate from percentage to decimal
        let varCommission = this.commission / 100;
        
        let bookmakerMoneyWin = ((this.stake * this.backOdds));
        //importeAC = (bookmakerMoneyWin - (this.refundRet / 100) * this.refundValue) / (this.layOdds - varCommission)
        // Calculate the amount to lay on the exchange
        let layStake = (bookmakerMoneyWin - (this.refundRet / 100) * this.refundValue) / (this.layOdds - varCommission);
        //let layStake = exchangeMoneywing * this.layOdds;

        // Calculation of potential profit
        let bookmakerWin = this.stake * (this.backOdds - 1);
        let exchangeWin = layStake*(1-varCommission);

        // Calculation of risk on the exchange
        const risk = layStake * (this.layOdds - 1);

        // Calculation of total profit (net profit)
        let totalProfit = bookmakerWin - risk;

        let freeBetBookmakerProfit = 0;
        let freeBetExchangeProfit = (this.refundRet / 100) * this.refundValue;

        // Rating calculation
        const rating = (((totalProfit + this.stake) / this.stake) * 100)-100;

        this.result = {
            layStake: layStake.toFixed(2),
            risk: risk.toFixed(2),
            totalProfit: totalProfit.toFixed(2),
            rating: rating.toFixed(2),
            bookmakerWin: bookmakerWin.toFixed(2),
            bookmakerLoss: this.stake.toFixed(2),
            freeBetBookmakerProfit: freeBetBookmakerProfit.toFixed(2), 
            freeBetExchangeProfit: freeBetExchangeProfit.toFixed(2),
            exchangeWin: exchangeWin.toFixed(2),
            exchangeLoss: risk.toFixed(2),
            message: `Con cuota ${this.layOdds}, podrías apostar ${layStake.toFixed(2)}€`
        };
      } else {
          this.result = 'Por favor, ingrese todas las cuotas';
      }
    },
    calculate() {
      if (this.betType === 'normal') {
        this.calculateNormal();
      } else if (this.betType === 'gratuita') {
        this.calculateFree();
      } else if (this.betType === 'reembolso') {
        this.calculateRefund();
      }
      /*if (this.backOdds && this.layOdds) {
        /* CALCULADORA NORMAL
        // Convert commission rate from percentage to decimal
        let varCommission = this.commission / 100;
        
        // Calculate the amount to lay on the exchange
        let layStake = (this.stake * this.backOdds) / (this.layOdds-varCommission);

        // Calculation of potential profit
        let bookmakerWin = this.stake * (this.backOdds - 1);
        let exchangeWin = layStake*(1-varCommission);

        // Calculation of risk on the exchange
        const risk = layStake * (this.layOdds - 1);

        // Calculation of total profit (net profit)
        let totalProfit = bookmakerWin - risk; 

        // Convert commission rate from percentage to decimal
        let varCommission = this.commission / 100;
        
        // CALCULADORA GRATUITA - TODO: Falta ajustar valores pero el calculo está bien
        // Calculate the amount to lay on the exchange
        let layStake = ((this.stake * this.backOdds)-this.stake) / (this.layOdds-varCommission);

        // Calculation of potential profit
        let bookmakerWin = this.stake * (this.backOdds - 1);
        let exchangeWin = layStake*(1-varCommission);

        // Calculation of risk on the exchange
        const risk = layStake * (this.layOdds - 1);

        // Calculation of total profit (net profit)
        let totalProfit = bookmakerWin - risk;

        // Rating calculation
        const rating = ((totalProfit + this.stake) / this.stake) * 100;

        this.result = {
            layStake: layStake.toFixed(2),
            risk: risk.toFixed(2),
            totalProfit: totalProfit.toFixed(2),
            rating: rating.toFixed(2),
            bookmakerWin: bookmakerWin.toFixed(2),
            bookmakerLoss: this.stake.toFixed(2),
            exchangeWin: exchangeWin.toFixed(2),
            exchangeLoss: risk.toFixed(2),
            message: `Con cuota ${this.layOdds}, podrías apostar ${layStake.toFixed(2)}€`
        };*/
      /*} else {
          this.result = 'Por favor, ingrese todas las cuotas';
      }*/
    },
    clear() {
      this.stake = 10;
      this.backOdds = null;
      this.layOdds = null;
      this.commission = 2;
      this.refundValue = 10;
      this.refundRet = 70;
      this.result = null;
    },
    setDefaultRefundValues() {
      if (this.betType === 'reembolso') {
        this.refundValue = this.stake;
        this.refundRet = 70;
      }
    },
    updateRefundValue() {
      if (this.betType === 'reembolso') {
        this.refundValue = this.stake;
      }
    }
  },
  watch: {
    stake(newValue) {
      if (this.betType === 'reembolso') {
        this.refundValue = newValue;
      }
    }
  }
};
</script>

<style scoped>
.calculator {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  font-size: 1.5em;
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
}

.form-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 15px;
}

.form-group {
  flex: 1;
}

.form-row.blue {
  background-color: #d4e9ff;
  padding: 10px;
  border-radius: 5px;
}

.form-row.red {
  background-color: #ffd4d4;
  padding: 10px;
  border-radius: 5px;
}

.form-row.green {
  background-color: #d4ffd4;
  padding: 10px;
  border-radius: 5px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input,
select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.input-group {
  display: flex;
  align-items: center;
}

.input-group span {
  margin-left: 10px;
}

button {
  background-color: #f0ad4e;
  border: none;
  padding: 10px;
  margin-top: 10px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1em;
  width: calc(100% - 20px);
  margin: 10px;
}

button.calculate {
  background-color: #f0ad4e;
}

button.clear {
  background-color: #f0ad4e;
}

button:hover {
  background-color: #ec971f;
}

.result-container {
  margin-top: 20px;
}

.result {
  text-align: center;
  padding: 20px;
  background-color: #333;
  color: white;
  border-radius: 10px;
}

.breakdown {
  margin-top: 20px;
}

.breakdown table {
  width: 100%;
  border-collapse: collapse;
}

.breakdown th,
.breakdown td {
  padding: 10px;
  text-align: center;
}

.breakdown th {
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
}

.breakdown td {
  background-color: #fff;
}

.breakdown td:first-of-type {
  background-color: #f0f0f0;
  font-weight: bold;
}

.breakdown tr:nth-child(2) td:first-of-type {
  background-color: #f0f0f0;
}
</style>
