<template>
  <section class="banner">
    <div class="banner-content">
      <h1>Gana con tus apuestas deportivas</h1>
      <p>Descubre las mejores estrategias y herramientas</p>
      <!--<router-link to="/register" class="cta-button">Regístrate Gratis</router-link>-->
    </div>
  </section>
</template>

<script>
export default {
  name: 'SiteBanner'
};
</script>

<style scoped>
.banner {
  background-color: lightgray; /* Fondo en gris claro */
  padding: 50px 20px;
  text-align: center;
}

.banner-content {
  max-width: 800px;
  margin: 0 auto;
}

.banner h1 {
  color: darkgray; /* Texto principal en gris oscuro */
  font-size: 2.5em;
  margin-bottom: 20px;
}

.banner p {
  color: darkgray; /* Subtítulos en gris oscuro */
  font-size: 1.5em;
  margin-bottom: 30px;
}

.cta-button {
  background-color: #50C878; /* Verde Esmeralda */
  color: white; /* Texto en blanco */
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1.2em;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #45B569; /* Tono más oscuro de verde en hover */
}
</style>
