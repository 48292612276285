<template>
  <section class="user-testimonials">
    <div class="container">
      <h2>Lo que dicen nuestros usuarios</h2>
      <div class="testimonials-list">
        <div class="testimonial-card" v-for="testimonial in testimonials" :key="testimonial.id">
          <p class="testimonial-text">{{ testimonial.text }}</p>
          <p class="testimonial-user">{{ testimonial.user }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'UserTestimonials',
  data() {
    return {
      testimonials: [
        {
          id: 1,
          text: 'Esta plataforma me ha ayudado a mejorar mis apuestas y ganar más consistentemente. ¡Muy recomendable!',
          user: 'Juan Pérez'
        },
        {
          id: 2,
          text: 'Las herramientas y estrategias ofrecidas aquí son de primera clase. Nunca había visto algo así antes.',
          user: 'María García'
        },
        {
          id: 3,
          text: 'El soporte al cliente es increíble. Siempre están disponibles para ayudarme con cualquier pregunta.',
          user: 'Carlos Rodríguez'
        }
      ]
    };
  }
};
</script>

<style scoped>
.user-testimonials {
  background-color: white;
  padding: 50px 20px;
  text-align: center;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

h2 {
  color: darkgray; /* Título en gris oscuro */
  font-size: 2em;
  margin-bottom: 30px;
}

.testimonials-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.testimonial-card {
  background-color: lightgray; /* Fondo de tarjeta en gris claro */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
  text-align: left;
}

.testimonial-text {
  color: darkgray; /* Texto del testimonio en gris oscuro */
  font-size: 1em;
  margin-bottom: 10px;
}

.testimonial-user {
  color: darkgray; /* Usuario en gris oscuro */
  font-size: 0.9em;
  font-style: italic;
  text-align: right;
}
</style>
