<template>
  <div class="terms">
    <h1>Términos y Condiciones</h1>
    <p>Aquí van los términos y condiciones de tu servicio...</p>
  </div>
</template>

<script>
export default {
  name: 'TermsPage'
};
</script>

<style scoped>
.terms {
  padding: 20px;
}
</style>
