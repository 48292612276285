<template>
  <header class="header">
    <div class="container">
      <div class="logo">
        <img src="@/assets/logo.png" alt="Apuestas Rentables" />
      </div>
      <nav class="nav" :class="{ open: isMenuOpen }">
        <router-link to="/" class="nav-link">Inicio</router-link>
        <!--<router-link to="/services" class="nav-link">Servicios</router-link>
        <router-link to="/tools" class="nav-link">Herramientas</router-link>
        <router-link to="/blog" class="nav-link">Blog</router-link>
        <router-link to="/contact" class="nav-link">Contacto</router-link>-->
      </nav>
      <div class="auth-and-social">
        <!--<router-link to="/auth" class="nav-link auth-link">
          <span class="icon">👤</span> Iniciar Sesión
        </router-link>
        <router-link to="/auth" class="nav-link button green-button">
          Unirme
        </router-link>-->
        <div class="social-links">
          <a href="#" target="_blank" class="social-icon">🔗</a>
          <a href="#" target="_blank" class="social-icon">🔗</a>
          <a href="#" target="_blank" class="social-icon">🔗</a>
        </div>
      </div>
      <div class="hamburger" @click="toggleMenu">
        <div class="line" />
        <div class="line" />
        <div class="line" />
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'AppHeader',
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    }
  },
  computed: {
    isLoggedIn() {
      return !!localStorage.getItem('user-token');
    }
  }
};
</script>

<style scoped>
.header {
  background-color: navy;
  color: white;
  padding: 10px 20px;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.logo img {
  height: 50px;
}

.nav {
  display: flex;
  gap: 15px;
  justify-content: center;
  flex: 1;
}

.nav-link {
  color: white;
  text-decoration: none;
  font-size: 16px;
}

.nav-link:hover {
  text-decoration: underline;
}

.auth-and-social {
  display: flex;
  align-items: center;
  gap: 10px;
}

.auth-link {
  display: flex;
  align-items: center;
  gap: 5px;
  color: white;
  text-decoration: none;
}

.green-button {
  background-color: #50C878; /* Verde Esmeralda */
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.green-button:hover {
  background-color: #45B569;
}

.social-links {
  display: flex;
  gap: 10px;
}

.social-icon {
  color: white;
  text-decoration: none;
  font-size: 20px;
}

.social-icon:hover {
  color: #50C878;
}

.hamburger {
  display: none;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
}

.hamburger .line {
  width: 25px;
  height: 3px;
  background-color: white;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .nav {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: navy;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 20px 0;
    display: none;
  }

  .nav.open {
    display: flex;
  }

  .auth-and-social {
    flex-direction: column;
  }

  .hamburger {
    display: flex;
  }
}
</style>
