<template>
  <footer class="footer">
    <div class="container">
      <div class="footer-section">
        <h3>Sobre Nosotros</h3>
        <p>Somos una plataforma dedicada a ayudarte a maximizar tus ganancias en apuestas deportivas con estrategias probadas y herramientas avanzadas.</p>
      </div>
      <div class="footer-section">
        <h3>Términos Legales</h3>
        <ul>
          <li><router-link to="/terms">Términos y Condiciones</router-link></li>
          <li><router-link to="/privacy">Política de Privacidad</router-link></li>
        </ul>
      </div>
      <div class="footer-section">
        <h3>Redes Sociales</h3>
        <div class="social-icons">
          <!--<a href="#"><img src="@/assets/facebook-icon.png" alt="Facebook"></a>
          <a href="#"><img src="@/assets/twitter-icon.png" alt="Twitter"></a>
          <a href="#"><img src="@/assets/instagram-icon.png" alt="Instagram"></a>-->
        </div>
      </div>
      <div class="footer-section">
        <h3>Información de Contacto</h3>
        <p>Email: info@apuestasrentables.com</p>
        <p>Teléfono: +34 123 456 789</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter'
};
</script>

<style scoped>
.footer {
  background-color: navy;
  color: white;
  padding: 40px 20px;
  text-align: left;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-section {
  flex: 1 1 200px;
  margin: 10px;
}

.footer-section h3 {
  font-size: 1.2em;
  margin-bottom: 15px;
}

.footer-section p,
.footer-section ul,
.footer-section a {
  color: white;
  font-size: 0.9em;
  margin: 5px 0;
  text-decoration: none;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section li {
  margin-bottom: 10px;
}

.social-icons a {
  margin-right: 10px;
}

.social-icons img {
  width: 30px;
  height: 30px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .footer-section {
    flex: 1 1 100%;
    margin-bottom: 20px;
  }
}
</style>
